import { lazy } from "react";

// import About from "./Components/About/About";
// import AllProducts from "./Components/AllProducts/AllProducts";
// import BoardOfDirectors from "./Components/BoardOfDirectors/BoardOfDirectors";
// import Careers from "./Components/Careers/Careers";
// import CareersList from "./Components/Careers/CareersList";
// import Clients from "./Components/Clients/Clients";
// import Contact from "./Components/Contact/Contact";
// import DigitalAffiliation from "./Components/DigitalAffiliation/DigitalAffiliation";
// import Disclaimer from "./Components/Disclaimer/Disclaimer";
// import Ecos from "./Components/Ecos/Ecos";
// import EdoSuite from "./Components/EdoSuite/EdoSuite";
// import Enach from "./Components/Enach/Enach";
// import ESign from "./Components/ESign/ESign";
// import GetKyc from "./Components/GetKyc/GetKyc";
// import GetStarted from "./Components/GetStarted/GetStarted";
// import Investers from "./Components/Investers/Investers";
// import Leadership from "./Components/Leadership/Leadership";
// import LotSms from "./Components/LotSms/LotSms";
// import News from "./Components/News/News";
// import NewsLetters from "./Components/NewsLetters/NewsLetters";
// import OfflineEKyc from "./Components/OfflineEKyc/OfflineEKyc";
// import PanVerification from "./Components/PanVerification/PanVerification";
// import Partners from "./Components/Partners/Partners";
// import PressReleases from "./Components/PressReleases/PressReleases";
// import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
// import Signup from "./Components/Signup/Signup";
// import SubsidyManagementSystem from "./Components/SubsidyManagementSystem/SubsidyManagementSystem";
// import SynZap from "./Components/SynZap/SynZap";
// import Videos from "./Components/Videos/Videos";
// import Webinars from "./Components/Webinars/Webinars";
// import ZenAsa from "./Components/ZenAsa/ZenAsa";
// import ZenAua from "./Components/ZenAua/ZenAua";
// import NotFound from "./Components/NotFound/NotFound";
// import Home from "./Components/Home/Home";
// import JulyNL from "./Components/NewsLetters/Month/JulyNL";
// import AugustNL from "./Components/NewsLetters/Month/AugustNL";
// import SeptemberNL from "./Components/NewsLetters/Month/SeptemberNL";
// import OctoberNL from "./Components/NewsLetters/Month/OctoberNL";
// import NovemberNL from "./Components/NewsLetters/Month/NovemberNL";
// import SeptemberPR from "./Components/PressReleases/Month/SeptemberPR";
// import DecemberPR from "./Components/PressReleases/Month/DecemberPR";
// import DecemberNL from "./Components/NewsLetters/Month/DecemberNL";


const About = lazy(() => import("./Components/About/About"));
const AllProducts = lazy(() => import("./Components/AllProducts/AllProducts"));
// const BoardOfDirectors = lazy(() => import("./Components/BoardOfDirectors/BoardOfDirectors"));
// const Careers = lazy(() => import("./Components/Careers/Careers"));
// const CareersList = lazy(() => import("./Components/Careers/CareersList"));
const Clients = lazy(() => import("./Components/Clients/Clients"));
const Contact = lazy(() => import("./Components/Contact/Contact"));
const DigitalAffiliation = lazy(() => import("./Components/DigitalAffiliation/DigitalAffiliation"));
const Disclaimer = lazy(() => import("./Components/Disclaimer/Disclaimer"));
const Ecos = lazy(() => import("./Components/Ecos/Ecos"));
const EdoSuite = lazy(() => import("./Components/EdoSuite/EdoSuite"));
const Enach = lazy(() => import("./Components/Enach/Enach"));
const ESign = lazy(() => import("./Components/ESign/ESign"));
const GetKyc = lazy(() => import("./Components/GetKyc/GetKyc"));
const GetStarted = lazy(() => import("./Components/GetStarted/GetStarted"));
const Investers = lazy(() => import("./Components/Investers/Investers"));
const Leadership = lazy(() => import("./Components/Leadership/Leadership"));
const LotSms = lazy(() => import("./Components/LotSms/LotSms"));
const News = lazy(() => import("./Components/News/News"));
const NewsLetters = lazy(() => import("./Components/NewsLetters/NewsLetters"));
const OfflineEKyc = lazy(() => import("./Components/OfflineEKyc/OfflineEKyc"));
const PanVerification = lazy(() => import("./Components/PanVerification/PanVerification"));
const Partners = lazy(() => import("./Components/Partners/Partners"));
const PressReleases = lazy(() => import("./Components/PressReleases/PressReleases"));
const PrivacyPolicy = lazy(() => import("./Components/PrivacyPolicy/PrivacyPolicy"));
const FalconPrivacyPolicy = lazy(() => import("./Components/FalconPrivacyPolicy/FalconPrivacyPolicy"));
// const Signup = lazy(() => import("./Components/Signup/Signup"));
const SubsidyManagementSystem = lazy(() => import("./Components/SubsidyManagementSystem/SubsidyManagementSystem"));
const SynZap = lazy(() => import("./Components/SynZap/SynZap"));
const Videos = lazy(() => import("./Components/Videos/Videos"));
// const Webinars = lazy(() => import("./Components/Webinars/Webinars"));
const ZenAsa = lazy(() => import("./Components/ZenAsa/ZenAsa"));
const ZenAua = lazy(() => import("./Components/ZenAua/ZenAua"));
const Vkyc = lazy(() => import("./Components/Vkyc/Vkyc"));
// const NotFound = lazy(() => import("./Components/NotFound/NotFound"));
const Home = lazy(() => import("./Components/Home/Home"));
const JulyNL = lazy(() => import("./Components/NewsLetters/Month/JulyNL"));
const AugustNL = lazy(() => import("./Components/NewsLetters/Month/AugustNL"));
const SeptemberNL = lazy(() => import("./Components/NewsLetters/Month/SeptemberNL"));
const OctoberNL = lazy(() => import("./Components/NewsLetters/Month/OctoberNL"));
const NovemberNL = lazy(() => import("./Components/NewsLetters/Month/NovemberNL"));
const SeptemberPR = lazy(() => import("./Components/PressReleases/Month/SeptemberPR"));
const DecemberPR = lazy(() => import("./Components/PressReleases/Month/DecemberPR"));
const July_22_PR = lazy(() => import("./Components/PressReleases/Month/july_22_PR"));
const DecemberNL = lazy(() => import("./Components/NewsLetters/Month/DecemberNL"));
const Contactform = lazy(() => import("./Components/Contactform/Contactform"));

export const routes = [
    { path: '/', exact: true, name: 'Home', component: Home },
    { path: '/about', exact: true, name: 'About', component: About },
    { path: '/allproducts', exact: true, name: 'AllProducts', component: AllProducts },
    // { path: '/board-of-directors', exact: true, name: 'BoardOfDirectors', component: BoardOfDirectors },
    // { path: '/careers', exact: true, name: 'Careers', component: Careers },
    // { path: '/careerslist', exact: true, name: 'CareersList', component: CareersList },
    { path: '/clients', exact: true, name: 'Clients', component: Clients },
    { path: '/contact', exact: true, name: 'Contact', component: Contact },
    { path: '/digital-affiliation', exact: true, name: 'DigitalAffiliation', component: DigitalAffiliation },
    { path: '/disclaimer', exact: true, name: 'Disclaimer', component: Disclaimer },
    { path: '/ecos', exact: true, name: 'Ecos', component: Ecos },
    { path: '/edo-suite', exact: true, name: 'EdoSuite', component: EdoSuite },
    { path: '/enach', exact: true, name: 'Enach', component: Enach },
    { path: '/e-sign', exact: true, name: 'ESign', component: ESign },
    { path: '/getKyc', exact: true, name: 'GetKyc', component: GetKyc },
    { path: '/get-started', exact: true, name: 'GetStarted', component: GetStarted },
    { path: '/investers', exact: true, name: 'Investers', component: Investers },
    { path: '/leadership', exact: true, name: 'Leadership', component: Leadership },
    { path: '/lotsms', exact: true, name: 'LotSms', component: LotSms },
    { path: '/media', exact: true, name: 'News', component: News },
    { path: '/newsletters', exact: true, name: 'NewsLetters', component: NewsLetters },
    { path: '/newsletters/july', exact: true, name: 'JulyNL', component: JulyNL },
    { path: '/newsletters/august', exact: true, name: 'AugustNL', component: AugustNL },
    { path: '/newsletters/september', exact: true, name: 'SeptemberNL', component: SeptemberNL },
    { path: '/newsletters/october', exact: true, name: 'OctoberNL', component: OctoberNL },
    { path: '/newsletters/november', exact: true, name: 'NovemberNL', component: NovemberNL },
    { path: '/newsletters/december', exact: true, name: 'DecemberNL', component: DecemberNL },
    { path: '/ekyc', exact: true, name: 'OfflineEKyc', component: OfflineEKyc },
    { path: '/panverification', exact: true, name: 'PanVerification', component: PanVerification },
    { path: '/partners', exact: true, name: 'Partners', component: Partners },
    { path: '/pressrelease', exact: true, name: 'PressReleases', component: PressReleases },
    { path: '/pressrelease/september', exact: true, name: 'SeptemberPR', component: SeptemberPR },
    { path: '/pressrelease/december', exact: true, name: 'DecemberPR', component: DecemberPR },
    { path: '/pressrelease/july22', exact: true, name: 'July_22_PR', component: July_22_PR },
    { path: '/privacy-policy', exact: true, name: 'PrivacyPolicy', component: PrivacyPolicy },
    { path: '/falcon-privacy-policy',exact:true, name: 'FalconPrivacyPolicy', component: FalconPrivacyPolicy },
    // { path: '/signup', exact: true, name: 'Signup', component: Signup },
    { path: '/sms', exact: true, name: 'SubsidyManagementSystem', component: SubsidyManagementSystem },
    { path: '/synzap', exact: true, name: 'SynZap', component: SynZap },
    { path: '/videos', exact: true, name: 'Videos', component: Videos },
    // { path: '/webinars', exact: true, name: 'Webinars', component: Webinars },
    { path: '/zen-asa', exact: true, name: 'ZenAsa', component: ZenAsa },
    { path: '/zen-aua', exact: true, name: 'ZenAua', component: ZenAua },
    { path: '/video-kyc', exact: true, name: 'Vkyc', component: Vkyc },
    // { path: '/404', exact: true, name: 'NotFound', component: NotFound },
    { path: '/contactform',exact:true, name: 'Contactform', component: Contactform },
];

export default routes;