import React, { Component } from 'react';
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import Aux from '../../../hoc/Aux';
import { Link } from 'react-router-dom';


export class M2PMenu extends Component {  

    toggleOrCloseDropdown = (openDropdown) => {     
        const openDropdownMenu = document.querySelector(`[aria-labelledby="${openDropdown}"]`);
        const isOpen = openDropdownMenu.classList.contains('show'); 
       
        this.closeAllDropdowns();
    
        if (!isOpen) {
          openDropdownMenu.classList.add('show');
          if (openDropdown === 'navbarDropdown4') {   
            document.querySelector(`[aria-labelledby="navbarDropdown2"]`).classList.add('show');
          }
        }
    };  
    
    closeAllDropdowns = () => {
    document.querySelector(`[aria-labelledby="navbarDropdown1"]`).classList.remove('show');
    document.querySelector(`[aria-labelledby="navbarDropdown2"]`).classList.remove('show');
    document.querySelector(`[aria-labelledby="navbarDropdown3"]`).classList.remove('show');
    document.querySelector(`[aria-labelledby="navbarDropdown4"]`).classList.remove('show');
    };  

    handleResize = () => {
    if (window.innerWidth >= 800) {
        this.closeAllDropdowns();
    }
    };  

    handleClickOutside = (event) => {
    if (window.innerWidth < 800) {
        this.closeAllDropdowns();
    }
    };  

    componentDidMount() {
    document.getElementById('navbarDropdown1').addEventListener('click', (event) => {
        if (window.innerWidth < 800) {
        event.stopPropagation();
        this.toggleOrCloseDropdown('navbarDropdown1', 'navbarDropdown2', 'navbarDropdown3', 'navbarDropdown4');
        }
    });

    document.getElementById('navbarDropdown2').addEventListener('click', (event) => {
        if (window.innerWidth < 800) {
        event.stopPropagation();
        this.toggleOrCloseDropdown('navbarDropdown2', 'navbarDropdown1', 'navbarDropdown3', 'navbarDropdown4');
        }
    });

    document.getElementById('navbarDropdown3').addEventListener('click', (event) => {
        if (window.innerWidth < 800) {
        event.stopPropagation();
        this.toggleOrCloseDropdown('navbarDropdown3', 'navbarDropdown1', 'navbarDropdown2', 'navbarDropdown4');
        }
    });

    document.getElementById('navbarDropdown4').addEventListener('click', (event) => {
        if (window.innerWidth < 800) {
        event.stopPropagation();      
        this.closeAllDropdowns();
        document.querySelector(`[aria-labelledby="navbarDropdown2"]`).classList.add('show');
        document.querySelector(`[aria-labelledby="navbarDropdown4"]`).classList.add('show');
        }
    });

    window.addEventListener('resize', this.handleResize);
    document.addEventListener('click', this.handleClickOutside);
    }   

    // add zIndex 

    handleClick = () => {
        // Select the target element
        const targetElement = document.querySelector('.headerMain');  
    
        if (targetElement) {
          // Toggle the class on the element
          targetElement.classList.toggle('zIndex');
        }
      };


    render() {
        return (
            <Aux>
                <>
                    <div className={`headerMain`}>          
                        <nav className="navbar navbar-expand-md cst_navbar">
                            <div className="container-fluid">
                                <div className="site_logo">
                                    <a className="navbar-brand" href="https://m2pfintech.com/" target="_blank">
                                        <img src="https://m2p-website-static-files.s3.ap-south-1.amazonaws.com/images/m2p-logo.png"
                                            alt="" />
                                    </a>
                                </div>
                                <input onClick={this.handleClick} className="menu-btn" type="checkbox" id="menu-btn" />    
                                <label className="menu-icon" for="menu-btn"><span className="navicon"></span></label>
                                <div className="d-flex justify-content-center navbar_div">
                                    <div className="collapse navbar-collapse" id="navbarCollapsible">
                                        <ul className="navbar-nav thm-nav">
                                            <li className="nav-item dropdown has-mega-menu">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span className="inner">
                                                        <span className="normal">Products</span>
                                                        <span className="hover">Products</span>
                                                    </span>
                                                </a>
                                                <ul className="dropdown-menu cstDrop" aria-labelledby="navbarDropdown1">
                                                    <div className="row g-0">
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="menuTitle">
                                                                <div className="menuTitle_wrap d-flex align-items-center">
                                                                    <div className="menuIcon_sec"> <img
                                                                        src="https://m2p-website-static-files.s3.ap-south-1.amazonaws.com/images/menu-icon-1.png"
                                                                        className="w-100" alt="" />
                                                                    </div>
                                                                    <div className="menuTxt f-W-N f-sz-20"> Payments</div>
                                                                </div>
                                                            </div>
                                                            <ul className="list-unstyled mb-0">
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/prepaid-cards">Prepaid Cards</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/cross-border-payments">Cross-Border
                                                                    Payments</a></li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/pay-later-plus">Pay Later +</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/fleet-drive">Fleet Drive</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/upi">UPI</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/merchant-acquiring-solutions/">Merchant
                                                                    Acquiring
                                                                    Solutions</a> </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="menuTitle">
                                                                <div className="menuTitle_wrap d-flex align-items-center">
                                                                    <div className="menuIcon_sec"> <img
                                                                        src="https://m2p-website-static-files.s3.ap-south-1.amazonaws.com/images/menu-icon-2.png"
                                                                        className="w-100" alt="" />
                                                                    </div>
                                                                    <div className="menuTxt f-W-N f-sz-20"> Lending </div>
                                                                </div>
                                                            </div>
                                                            <ul className="list-unstyled mb-0">
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/credit-cards">Credit Cards</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/buy-now-pay-later">BNPL</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/loan-origination-system">Loan
                                                                    Origination System
                                                                    (Finflux)</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/loan-management-system">Loan Management
                                                                    System
                                                                    (Finflux) </a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/debt-collections">Debt Collections </a>
                                                                </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/credit-line-on-upi/">Credit Line on
                                                                    UPI</a> </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="menuTitle">
                                                                <div className="menuTitle_wrap d-flex align-items-center">
                                                                    <div className="menuIcon_sec"> <img
                                                                        src="https://m2p-website-static-files.s3.ap-south-1.amazonaws.com/images/menu-icon-3.png"
                                                                        className="w-100" alt="" />
                                                                    </div>
                                                                    <div className="menuTxt f-W-N f-sz-20"> Banking </div>
                                                                </div>
                                                            </div>
                                                            <ul className="list-unstyled mb-0">
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/turing-core-banking ">Turing (Core
                                                                    Banking)</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/debit-cards">Debit Cards</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/neobanking">Neobanking</a> </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="menuTitle">
                                                                <div className="menuTitle_wrap d-flex align-items-center">
                                                                    <div className="menuIcon_sec"> <img
                                                                        src="https://m2p-website-static-files.s3.ap-south-1.amazonaws.com/images/menu-icon-4.png"
                                                                        className="w-100" alt="" /> 
                                                                    </div>
                                                                    <div className="menuTxt f-W-N f-sz-20"> Value Added Services </div>
                                                                </div>
                                                            </div>
                                                            <ul className="list-unstyled mb-0">
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/recon360">Recon360</a> </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/kyc-suite">KYC Suite (Syntizen)</a>
                                                                </li>
                                                                <li className="list-block-item"> <a className="dropdown-item" target="_blank"
                                                                    href="https://m2pfintech.com/acs">ACS</a> </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span className="inner">
                                                        <span className="normal">About</span>
                                                        <span className="hover">About</span>
                                                    </span>
                                                </a>
                                                <ul className="dropdown-menu about_submenu cstDrop" aria-labelledby="navbarDropdown2">
                                                    <div className="dropCst_section">
                                                        <div className="row g-0">
                                                            <div className="col-lg-12 col-md-12 col-xs-12">
                                                                <ul className="list-unstyled mb-0">
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/about-us"> Who we are </a>
                                                                    </li>
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/our-story"> Our story </a>
                                                                    </li>
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/know-our-founders"> Know our
                                                                            founders </a>
                                                                    </li>
                                                                    <li className="nav-item dropdown has-mega-menu jobs_nav">
                                                                        <a className="dropdown-item nav-link dropdown-toggle"
                                                                            id="navbarDropdown4" role="button" data-bs-toggle="dropdown"
                                                                            aria-expanded="false" href="/#"> Job
                                                                            opportunities </a>
                                                                        <ul className="dropdown-menu jobs_submenu cstDrop"
                                                                            aria-labelledby="navbarDropdown4">
                                                                            <div className="dropCst_section">
                                                                                <div className="row g-0">
                                                                                    <div className="col-lg-12 col-md-12 col-xs-12">
                                                                                        <ul className="list-unstyled mb-0">
                                                                                            <li className="list-block-item">
                                                                                                <a className="dropdown-item" target="_blank"
                                                                                                    href="https://m2pfintech.com/careers">
                                                                                                    Careers </a>
                                                                                            </li>
                                                                                            <li className="list-block-item">
                                                                                                <a className="dropdown-item" target="_blank"
                                                                                                    href="https://m2pfintech.com/moonlight">
                                                                                                    Moonlight with us </a>
                                                                                            </li>
                                                                                            <li className="list-block-item">
                                                                                                <a className="dropdown-item" target="_blank"
                                                                                                    href="https://m2pfintech.com/betterhalf">
                                                                                                    Better half </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ul>
                                                                    </li>
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://www.tether.community"> Our community </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span className="inner">
                                                        <span className="normal">Resources</span>
                                                        <span className="hover">Resources</span>
                                                    </span>
                                                </a>
                                                <ul className="dropdown-menu about_submenu cstDrop" aria-labelledby="navbarDropdown3">
                                                    <div className="dropCst_section">
                                                        <div className="row g-0">
                                                            <div className="col-lg-12 col-md-12 col-xs-12">
                                                                <ul className="list-unstyled mb-0">
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/blog"> Blog </a>
                                                                    </li>
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/news"> News </a>
                                                                    </li>
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/newsletter"> Newsletter </a>
                                                                    </li>
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/case-studies"> Case studies </a>
                                                                    </li>
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/white-paper"> White papers </a>
                                                                    </li>
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/reports"> Reports </a>
                                                                    </li>
                                                                    <li className="list-block-item">
                                                                        <a className="dropdown-item" target="_blank"
                                                                            href="https://m2pfintech.com/brand-assets"> Brand assets </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <a className="nav-link" href="https://m2pfintech.com/contact-us" target="_blank">
                                                    <span className="inner">
                                                        <span className="normal">Contact</span>
                                                        <span className="hover">Contact</span>
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>      
                </>

            </Aux>
        )
    }
}

export default M2PMenu;
